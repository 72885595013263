import HttpClient from "api/http-client";
import {
  TAccountCreate,
  TAccountPassword,
  TAccountUpdate,
  TAccountUpdatePTX,
  TCartItemOptions,
  TComboMenuItemConfiguration,
  TContactUpdate,
  TMobileCardPayTokens,
  TOrderType,
  TOrderingFor,
  TTODO,
} from "types";

export function searchRestaurants() {
  return new HttpClient().request<TTODO>({
    method: "GET",
    url: `/search/restaurants?show_closed=0&address=,+02571`,
  });
}

export function getRestaurant(restaurantId: string) {
  return new HttpClient().request<TTODO>({
    method: "get",
    url: `/restaurants/${restaurantId}`,
  });
}

export function getRestaurantAllowedOrderTime(
  restaurantId: string,
  orderType: TOrderType,
  date?: string
) {
  return new HttpClient().request<TTODO>({
    method: "get",
    url: `/restaurants/${restaurantId}/allowed_order_time?type=${orderType}${
      (date ?? "").length > 0 ? `&date=${date}` : ""
    }`,
    data: {
      type: orderType,
    },
  });
}

export function getRestaurantMenu(
  restaurantId: string,
  order_date?: string,
  eta?: string
) {
  const qsargs = [
    "include_combo=true",
    "show_unavailable=false",
    "fix_ids=true",
    "skip_options=true",
    "one_click=true",
  ];

  if (order_date && order_date !== "") {
    qsargs.push(`order_date=${order_date}`);
  }

  if (eta && eta !== "") {
    qsargs.push(`eta=${eta}`);
  }

  return new HttpClient().request<TTODO>({
    method: "get",
    url: `/restaurants/${restaurantId}/menu/tier?${qsargs.join("&")}`,
  });
}

export function getRestaurantMenuOptionImages(
  restaurantId: string,
) {
  return new HttpClient().request<TTODO>({
    method: "get",
    url: `/restaurants/${restaurantId}/menu/option_images`,
  });
}

interface ICreateOrderRequest {
  restaurant_id: string;
  type: "takeout" | "delivery";
  app?: string;
  source?: string;
}

export function createOrder(data: ICreateOrderRequest) {
  data.app = 'WebStore';
  data.source = 'WebStore';

  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders`,
    data: data,
  });
}

export function getOrder(orderId: string, token?: string) {
  return new HttpClient().request<TTODO>({
    method: "GET",
    url: `/orders/${orderId}${!!token ? `?access_token=${token}` : ``}`,
  });
}

export function getMenuItem(id: string) {
  return new HttpClient().request<TTODO>({
    method: "GET",
    url: `/menuitems/${id}`,
  });
}

interface IAddComboMenuItemToCartRequest {
  id: string; // combo menu item id
  items: TComboMenuItemConfiguration[];
}

export function addComboItemToOrder(
  orderId: string,
  data: IAddComboMenuItemToCartRequest
) {
  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}/items`,
    data: {
      combo_id: data.id,
      items: data.items.map((item, index) => ({
        id: item.id,
        combo_id: data.id,
        slot_index: index,
        options: item.options,
        notes: !!item.notes ? item.notes : undefined, // Ensures we don't send a notes if it's value is falsy
      })),
    },
  });
}

interface IAddMenuItemToCartRequest {
  id: string;
  size?: string;
  quantity: number;
  options: TCartItemOptions;
  notes?: string;
}

export function addItemToOrder(
  orderId: string,
  data: IAddMenuItemToCartRequest
) {
  data.notes ||= undefined; // Ensures we don't send a notes if it's value is falsy
  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}/items`,
    data: data,
  });
}

export function updateOrderItem(
  orderId: string,
  cartItemId: string,
  data: IAddMenuItemToCartRequest
) {
  data.notes ||= undefined; // Ensures we don't send a notes if it's value is falsy
  return new HttpClient().request<TTODO>({
    method: "PUT",
    url: `/orders/${orderId}/items/${cartItemId}`,
    data: data,
  });
}

export function removeItemFromOrder(
  orderId: string,
  itemId: string,
  isComboItem?: boolean
) {
  return new HttpClient().request<TTODO>({
    method: "DELETE",
    url: !isComboItem
      ? `/orders/${orderId}/items/${itemId}`
      : `/orders/${orderId}/combo/${itemId}`,
  });
}

interface IUpdateOrderRequest {
  type: TOrderType;
  orderingFor: TOrderingFor;
  orderDate?: string;
  eta?: string;
  customerAddress?: string;
  fields?: { [formName: string]: string };
  special_instructions?: string;
}

export function updateOrder(orderId: string, data: IUpdateOrderRequest) {
  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}`,
    data: {
      type: data.type,
      ordering_for: data.orderingFor,
      order_date: data.orderDate ?? undefined,
      eta: data.eta ?? undefined,
      customer_address: data.customerAddress ?? undefined,
    },
  });
}

export function validateOrder(orderId: string) {
  return new HttpClient().request<TTODO>({
    method: "GET",
    url: `/orders/${orderId}/validate`,
  });
}

export function getUserProfile(cardNumber: string, authorizationGrant: string) {
  const client = new HttpClient();
  client
    .setAuthGrant(authorizationGrant)
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);
  return client.request<TTODO>({
    method: "GET",
    url: `/account`,
  });
}

export function updateUserProfile(query: TAccountUpdate, cardNumber: string) {
  const client = new HttpClient();
  client
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);
  if (query.first_name && query.last_name) {
    query.name = `${query.first_name} ${query.last_name}`;
  }
  return client.request<TTODO>({
    method: "PUT",
    url: `/account`,
    data: query,
  });
}

export function validateAddress(
  address: {
    address: string;
    zip: string;
    address_line_2?: string;
    access_token: string;
  },
  cardNumber: string
) {
  const client = new HttpClient();
  client
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);
  return client.request<TTODO>({
    method: "POST",
    url: `/addresses/validate`,
    data: address,
  });
}

export function addAddress(
  address: {
    address: string;
    zip: string;
    address_line_2?: string;
    access_token: string;
  },
  cardNumber: string
) {
  const client = new HttpClient();
  client
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);
  return client.request<TTODO>({
    method: "POST",
    url: `/account/addresses`,
    data: address,
  });
}

export function updateUserContact(query: TContactUpdate, cardNumber: string) {
  const client = new HttpClient();
  client
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);

  return client.request<TTODO>({
    method: "PUT",
    url: `/account`,
    data: query,
  });
}

/**
 * Open Dining Update password
 * CURRENTLY DOES NOT WORK
 * @param query
 * @param cardNumber
 * @returns
 */
export function updateUserPassword(
  query: TAccountPassword,
  cardNumber: string
) {
  const client = new HttpClient();
  client
    .setNeedsMerchantId()
    .setGuestIdentifier("printed_card_number", cardNumber);

  return client.request<TTODO>({
    method: "POST",
    url: "/account/password",
    data: query,
  });
}

export function updateUserProfilePTX(
  query: TAccountUpdatePTX,
  cardNumber: string
) {
  const client = new HttpClient();
  client.setWordpress();
  if (query.first_name && query.last_name) {
    query.name = `${query.first_name} ${query.last_name}`;
  }

  return client.request<TTODO>({
    method: "POST",
    url: `/wp-json/nouria/v1/paytronix/profile`,
    data: { ...query, printed_card_number: cardNumber },
  });
}

export function mobilePayCard(query: TMobileCardPayTokens) {
  const client = new HttpClient();

  return client.request<TTODO>({
    method: "POST",
    url: "/account/mobile_pay_card",
    data: query,
  });
}

export interface IDeliveryAddressRequest {
  primaryAddress: string;
  secondaryAddress?: string;
  zip: string;
}

export function updateDeliveryAddress(
  orderId: string,
  data: IDeliveryAddressRequest
) {
  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}/delivery_address`,
    data: {
      address: data.primaryAddress,
      address_line_2: data.secondaryAddress,
      zip: data.zip,
    },
  });
}

/**
 * Get Locations from the WP API
 * TODO: type the return
 * @returns WpHttpClient<any>
 */
export const getLocations = (query: {
  state?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  filters?: string[];
}): HttpClient<any> => {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "GET",
    url: "/wp-json/nouria/v1/locations",
    params: query,
  });
};

/**
 * Send a request to the WP API to authenticate a user against Paytronix
 * @param username
 * @param password
 * @returns WpHttpClient<any>
 */
export const paytronixAuthenticate = (
  username: string,
  password: string
): HttpClient<any> => {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "POST",
    url: "/wp-json/nouria/v1/paytronix/login",
    data: {
      username,
      password,
    },
  });
};

/**
 * Refresh a token against paytronix
 * @param refresh_token
 * @returns WpHttpClient<any>
 */
export const paytronixRefresh = (
  username: string,
  refresh_token: string
): HttpClient<any> => {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "POST",
    url: "/wp-json/nouria/v1/paytronix/refresh",
    data: {
      username,
      refresh_token,
    },
  });
};

/**
 * Request a grant from Paytronix
 * @param username
 * @param access_token
 * @returns WpHttpClient<any>
 */
export const paytronixGrant = (
  username: string,
  access_token: string
): HttpClient<any> => {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "POST",
    url: "/wp-json/nouria/v1/paytronix/request_grant",
    data: {
      username,
      access_token,
    },
  });
};

export function paytronixRegister(query: TAccountCreate) {
  const client = new HttpClient();
  client.setWordpress();
  if (query.first_name && query.last_name) {
    query.name = `${query.first_name} ${query.last_name}`;
  }
  return client.request<TTODO>({
    method: "POST",
    url: `/wp-json/nouria/v1/paytronix/register`,
    data: query,
  });
}

export function paytronixResetPassword(email: string) {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "POST",
    url: `/wp-json/nouria/v1/paytronix/reset`,
    data: {
      email,
    },
  });
}

export function paytronixResetConfirm(code: string, password: string) {
  return new HttpClient().setWordpress().request<TTODO>({
    method: "POST",
    url: `/wp-json/nouria/v1/paytronix/confirm`,
    data: {
      code,
      password,
    },
  });
}

export interface IOrderData {
  type: TOrderType;
  orderingFor: TOrderingFor;
  orderDate?: string;
  eta?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  tip?: number;
  paymentType: "cc" | "pip" | string;
  saveCC?: boolean;
  card?: {
    token: string;
  };
  tokenType?: string;
  address?: string;
  address_line_2?: string;
  zip?: string;
  fields?: { [formName: string]: string };
  specialInstructions?: string;
  billingInfo?: {
    address?: string;
    zip?: string;
    city?: string;
    state?: string;
    country?: string;
  };
}

export function submitOrder(
  orderId: string,
  data: IOrderData,
  authorizationGrant?: string,
  cardNumber?: string
) {
  const payload: { [key: string]: any } = {
    order_type: data.type,
    ordering_for: data.orderingFor,
    first_name: data.firstName,
    last_name: data.lastName,
    phone: data.phone,
    email: data.email,
    tip: data.tip,
    payment_type: data.paymentType,
    save_cc: data.saveCC ?? false,
    card: data.card,
    token_type: data.tokenType,
    address: data.address ?? undefined,
    address_line_2: data.address_line_2 ?? undefined,
    zip: data.zip ?? undefined,
    special_instructions: data.specialInstructions ?? "",
    billing_address: data.billingInfo?.address ?? undefined,
    billing_zip: data.billingInfo?.zip ?? undefined,
    billing_city: data.billingInfo?.city ?? undefined,
    billing_state: data.billingInfo?.state ?? undefined,
    billing_country: data.billingInfo?.country ?? undefined,
  };

  if (authorizationGrant) {
    payload.provide = "px";
    payload["printed_card_number"] = cardNumber;
    payload["access_token"] = authorizationGrant;
  }


  if (data.fields) {
    Object.entries(data.fields).forEach(([formName, value]) => {
      payload[`field_${formName}`] = value;
    });
  }

  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}/submit`,
    data: payload,
  });
}

export function applyCouponToOrder(orderId: string, code: string) {
  return new HttpClient().request<TTODO>({
    method: "POST",
    url: `/orders/${orderId}/coupons`,
    data: {
      code,
    },
  });
}

export function removeCouponFromOrder(orderId: string, couponId: string) {
  return new HttpClient().request<TTODO>({
    method: "DELETE",
    url: `/orders/${orderId}/coupons/${couponId}`,
  });
}
