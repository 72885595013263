import classNames from "classnames";
import Breadcrumb from "components/Breadcrumb";
import ContextAlert from "components/ContextAlert";
import OptionSelect from "components/OptionSelect";
import OrderBasics from "components/checkout/OrderBasics";
import { useRestaurantContext } from "contexts/restaurant-context";
import { TRestaurantType } from "types";
import _ from "lodash";
import MenuHeader from "menu/Header";
import React, { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";

function Menu() {
  const { menu, restaurantBaseUrl, restaurant, restaurantType } =
    useRestaurantContext();
  //const itemsRef = useRef([]);
  const [menuItemGroupStates, setMenuItemGroupStates] = useState<{
    [groupId: number]: number;
  }>({});
  const [activeMenu, setActiveMenu] = useState<string>("");
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const menuRefPosition = menuRef.current?.offsetTop;
    if (!menuRefPosition) return;
    window.addEventListener("scroll", () => {
      if (window.scrollY > menuRefPosition + 100) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [menu]);
  useEffect(() => {
    if (menu) {
      setMenuItemGroupStates(
        menu.menu.reduce((acc: any, group: any) => {
          acc[group.id] = 12;
          return acc;
        }, {})
      );
      // setActiveMenu(menu.menu[0].name);

      setTimeout(() => {
        const hash = window.location.hash.substring(1); // Get the hash part of the URL without the '#'

        if (hash) {
          const element = document.getElementById(hash);
          if (element) {
            // Scroll to the element if it exists
            window.scrollTo({
              top: element.getBoundingClientRect().top + window.scrollY - 150,
              behavior: "smooth",
            });
          }
        }
      }, 500);
    }
  }, [menu]);

  useEffect(() => {
    if (activeMenu) {
      const element = document.getElementById(_.kebabCase(activeMenu));
      if (element) {
        const yOffset = -150;
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [activeMenu]);

  const handleShowMore = (groupId: number) => {
    setMenuItemGroupStates((prevState) => ({
      ...prevState,
      [groupId]: prevState[groupId] + 12,
    }));
  };

  if (!menu || !restaurant) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <section className="hero hero-wide-content hero-light-text">
        <div
          className="hero-banner-bg"
          style={{
            backgroundColor:
              restaurantType === TRestaurantType.NouriaKitchen
                ? "#3088d9"
                : restaurantType === TRestaurantType.NouriaCafe
                ? "#e1521e"
                : restaurantType === TRestaurantType.Amatos
                ? "#aa4086"
                : restaurantType === TRestaurantType.KrispyKrunch
                ? "#aa4086"
                : "#65b3e3",
          }}
        >
          {restaurantType === TRestaurantType.Normal && (
            <img
              className="hero-background"
              src="/wp-content/themes/nouria/assets/images/content/my-nouria-background.png"
              alt="cafe nouria background"
            />
          )}
        </div>
        <Breadcrumb
          items={[
            { uri: "/locations" ?? "", title: "Locations" },
            {
              uri: restaurantBaseUrl?.replace("/menu", "") ?? "",
              title: restaurant.name,
            },
            { uri: "#", title: "Menu" },
          ]}
          hero={true}
        />

        <MenuHeader />
      </section>

      <section className="py-6 py-md-8">
        <div className="container">
          <div className="row gx-xl-6 gy-4">
            <div className="col-lg-3 category-list">
              <StickyBox offsetTop={150} offsetBottom={20}>
                <nav className="page-nav page-nav-sticky" ref={menuRef}>
                  <p className="page-nav-title">Menus at this location</p>
                  <ul className="page-nav-list">
                    {menu.menu.map((menuItem: any, menuItemIdx: number) => (
                      <li
                        className="page-nav-item"
                        key={"page-nav" + menuItemIdx}
                      >
                        <a
                          key={`mi-${menuItemIdx}`}
                          href={`#${_.kebabCase(menuItem.name)}`}
                          className={classNames(
                            `page-nav-link`,
                            menuItem.name === activeMenu && "active"
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveMenu(menuItem.name);
                          }}
                        >
                          {menuItem.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </StickyBox>
            </div>
            <div className="col-lg-9 mt-2 mt-lg-4">
              {restaurant.menu_alert_text || restaurant.menu_alert_title ? (
                <div className="alert alert-info">
                  <h5 className="mb-0 text-white">
                    {restaurant.menu_alert_title}
                  </h5>
                  <p className="mb-0">{restaurant.menu_alert_text}</p>
                </div>
              ) : null}
              <ContextAlert />
              <OrderBasics />

              <div className="vstack gap-4">
                <div
                  ref={menuRef}
                  className={classNames(
                    "menu-categories",
                    showBackToTop ? "sticky" : ""
                  )}
                >
                  <OptionSelect
                    selection={activeMenu}
                    options={[
                      { label: "Choose A Category", value: "" },
                      ...menu.menu.map((menuItem: any) => {
                        return { value: menuItem.name, label: menuItem.name };
                      }),
                    ]}
                    onChange={(value) => {
                      setActiveMenu(value);
                    }}
                  />
                </div>
                {menu.menu.map(
                  (menuItemGroup: any, menuItemGroupIdx: number) => (
                    <div
                      key={`g-${menuItemGroupIdx}`}
                      id={_.kebabCase(menuItemGroup.name)}
                      className="s1"
                    >
                      <div className="hstack gap-3 mb-2">
                        <p className="flex-shrink-0 mb-0 fw-semibold text-text-hard">
                          {menuItemGroup.name}
                        </p>
                        <hr className="w-100 py-0" />
                      </div>
                      <div className="row row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-3">
                        {menuItemGroup.items
                          .slice(0, menuItemGroupStates[menuItemGroup.id])
                          .map((menuItem: any, menuItemIdx: number) => {
                            return (
                              <div
                                key={`g-${menuItemGroupIdx}-i-${menuItemIdx}`}
                                id={menuItem.id}
                              >
                                <Link
                                  className="menu-card"
                                  to={`/${menuItem.id}`}
                                  onClick={() => {}}
                                >
                                  {menuItem.new ? (
                                    <span className="menu-card-tag menu-item-new">
                                      New!
                                    </span>
                                  ) : (
                                    menuItem.is_sold_out && (
                                      <span className="menu-card-tag menu-item-out-of-stock">
                                        Out of Stock
                                      </span>
                                    )
                                  )}
                                  <figure className="menu-card-image">
                                    {menuItem.images[0] ? (
                                      <img
                                        src={menuItem.images[0]}
                                        alt={menuItem.name}
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src="/wp-content/themes/nouria/assets/images/content/no-image-available.png"
                                        alt={menuItem.name}
                                        className="mt-1"
                                      />
                                    )}
                                  </figure>
                                  <h6 className="menu-card-title">
                                    {menuItem.name}
                                  </h6>
                                </Link>
                              </div>
                            );
                          })}
                      </div>
                      {menuItemGroup.items.length >
                        menuItemGroupStates[menuItemGroup.id] && (
                        <div className="col text-center pb-2">
                          <button
                            onClick={() => handleShowMore(menuItemGroup.id)}
                            className="btn btn-sm"
                          >
                            Show more
                          </button>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const LoadingSkeleton: React.FC = () => {
  return (
    <>
      <section className="hero hero-wide-content">
        <Breadcrumb items={[]} hero={true} />
        <MenuHeader />
      </section>
      <section className="py-6 py-md-8">
        <div className="container">
          <div className="row gx-xl-6 gy-4">
            <div className="col-lg-3 category-list">
              <nav className="page-nav page-nav-sticky">
                <p className="page-nav-title">Menus at this location</p>
                {[...Array(8)].map((_, i) => (
                  <Skeleton key={`m-${i}`} height={40} />
                ))}
              </nav>
            </div>
            <div className="col-lg-9 mt-2 mt-lg-4">
              <div className="hstack gap-2 mt-0 p-3 mb-4 bg-body-tertiary rounded-3">
                <Skeleton height={100} />
              </div>
              <div className="vstack gap-4">
                {[...Array(4)].map((_, i) => (
                  <Fragment key={`m-${i}`}>
                    <div className="s1">
                      <div className="hstack gap-3 mb-2">
                        <p className="flex-shrink-0 mb-0 fw-semibold text-text-hard">
                          <Skeleton width={300} height={25} />
                        </p>
                        <hr className="w-100 py-0" />
                      </div>
                      <div className="row row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-3">
                        {[...Array(8)].map((_, j) => (
                          <div key={`mi-${j}`}>
                            <div className="menu-card">
                              <figure className="menu-card-image">
                                <Skeleton width={100} height={100} />
                              </figure>
                              <div className="menu-card-title mt-1">
                                <Skeleton height={20} width={100} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Menu;
