import AnonLayout from "components/auth/AnonLayout";
import ConfirmReset from "components/auth/ConfirmReset";
import LoginView from "components/auth/LoginForm";
import AuthLogout from "components/auth/Logout";
import ProfileContactForm from "components/auth/ProfileContactForm";
import ProfileLayout from "components/auth/ProfileLayout";
import ProfilePersonalInfoForm from "components/auth/ProfilePersonalInfoForm";
import RegisterForm from "components/auth/RegisterForm";
import ForgotPassword from "components/auth/ResetForm";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<AnonLayout />}>
        <Route path="/" element={<LoginView />} />
        <Route path="/login" element={<LoginView />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/confirm" element={<ConfirmReset />} />
        <Route path="/join" element={<RegisterForm />} />
        <Route path="*" element={<LoginView />} />
      </Route>

      <Route element={<ProfileLayout />}>
        <Route path="/profile" element={<ProfilePersonalInfoForm />} />
        <Route path="/contact" element={<ProfileContactForm />} />
        <Route path="/logout" element={<AuthLogout />} />
      </Route>
    </Route>
  ),
  {
    basename: "/auth",
  }
);
const AuthPage = () => {
  return <RouterProvider router={router} />;
};

export default AuthPage;
