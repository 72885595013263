import { Link } from "react-router-dom";
import { useRestaurantContext } from "../contexts/restaurant-context";
import React, { useState } from "react";

const OrderDebugger: React.FC = () => {
  const { restaurant, order, removeItemFromOrder, clearErrors } =
    useRestaurantContext();
  const [updating, setUpdating] = useState<boolean>(false);

  return (
    <div className="d-flex justify-content-between align-items-center py-2 my-2 px-3 fs-xs bg-light rounded">
      <div className="col-md-6">
        <pre>
          <p>RESTAURANT_ID: {restaurant?.id ?? "undefined"}</p>
          <p>ACTIVE_ORDER_ID: {order?.id ?? "N/A"}</p>
          {order && (
            <>
              <p>ITEMS_COUNT: {order?.items.length ?? 0}</p>
              <p>SUBTOTAL: ${order?.subtotal ?? 0}</p>
              <p>
                <button
                  onClick={(_) =>
                    (window.location.href = `/checkout/${order?.id}`)
                  }
                >
                  Head to checkout
                </button>
              </p>
            </>
          )}
          <p>
            <button onClick={(_) => clearErrors()}>Clear errors</button>
          </p>
        </pre>
      </div>
      <div className="col-md-6">
        {order?.items.map((item: any) => (
          <div
            key={item.id}
            className="d-flex align-items-center pt-2 px-3 fs-xs bg-light rounded"
          >
            <div className="px-2">
              {item.images && item.images.length > 0 && (
                <img src={item.images[0]} alt={item.name} width={30} />
              )}
            </div>
            <div className="flex-grow-1">
              <p className="mb-0">
                <strong>
                  {item.name}
                  {item.size && item.size !== "" && <>&nbsp;({item.size})</>}
                  &nbsp;x{item.quantity}
                </strong>
              </p>
              {item.options.length > 0 && (
                <p className="mb-0">
                  {item.options.map((option: any) => option.name).join(", ")}
                </p>
              )}
              <Link
                to={`/${item.menuitem}?edit=${item.id}`}
                className="text-muted"
              >
                (edit)
              </Link>
            </div>
            <div className="px-2">
              {!item.combo_id && (
                <button
                  type="button"
                  className="btn btn-sm btn-close"
                  disabled={updating}
                  onClick={async () => {
                    setUpdating(true);
                    await removeItemFromOrder(item.id);
                    setUpdating(false);
                  }}
                ></button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDebugger;
