/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  APILoadingStatus,
  Map,
  Marker,
  useApiLoadingStatus,
} from "@vis.gl/react-google-maps";
import ModalFilters from "components/locations/ModelFilters";
import LocationSearchForm from "components/locations/SearchForm";
import LocationSearchResults from "components/locations/SearchResults";
import {
  LocationsContextProvider,
  useLocationsContext,
} from "contexts/locations-context";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams, useSearchParams } from "react-router-dom";

const defaultCenter = { lat: 42.35672, lng: -71.05653 };
const defaultZoom = 10;

export default function LocationFinder() {
  return (
    <LocationsContextProvider>
      <Locations />
    </LocationsContextProvider>
  );
}

export function Locations() {
  const {
    locations,
    address,
    latitude,
    longitude,
    state,
    setState,
    filters,
    setAddress,
    setFilters,
    isLoading: locationsLoading,
    locationError: error,
    selectedLocation: selected,
    hoveredLocation: hovered,
    setHoveredLocation: setHovered,
    setSelectedLocation: setSelected,
  } = useLocationsContext();
  const [geolocationLoading, setGeolocationLoading] = useState(false);
  const [searchParams] = useSearchParams();
  let { locationState } = useParams();
  const mapStatus = useApiLoadingStatus();
  useEffect(() => {
    if (searchParams.has("address")) {
      const address = searchParams.get("address");
      if (address) {
        setAddress(address);
      }
    }
    if (searchParams.has("filters")) {
      const filters = searchParams.getAll("filters");
      setFilters(filters);
    }
  }, [searchParams, setAddress, setFilters]);

  useEffect(() => {
    setState(locationState);
  }, [locationState, setState]);

  // Breadcrumb disabled per NOUDD-209
  // const [breadcrumbs, setBreadcrumbs] = useState<
  //   { uri: string; title: string }[]
  // >([]);
  // useEffect(() => {
  //   setBreadcrumbs([
  //     { uri: state ? "/find-a-store" : "#", title: "Find a Store" },
  //     state
  //       ? { uri: `/find-a-store/${state}`, title: "Locations in " + state }
  //       : { uri: "", title: "" },
  //   ]);
  // }, [state]);
  return (
    <>
      <section className="store-locator">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-md-5">
              <div className="alert alert-info">
                To start an order, please search for a location.
              </div>
              <LocationSearchForm
                geolocationLoading={geolocationLoading}
                setGeolocationLoading={setGeolocationLoading}
              />
            </div>
          </div>
        </div>
        <div id="store-locator-map" className="store-locator-map">
          {mapStatus === APILoadingStatus.LOADED && (
            <Map
              defaultCenter={defaultCenter}
              defaultZoom={defaultZoom}
              disableDefaultUI={true}
              scrollwheel={true}
              zoomControl={true}
              maxZoom={16}
              styles={[
                {
                  featureType: "poi",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "transit",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
              ]}
            >
              {!locationsLoading &&
                !error &&
                (address || (latitude && longitude) || state || filters) &&
                Array.isArray(locations) &&
                locations.length > 0 &&
                locations
                  .filter((location: any) => {
                    return (
                      location.opening_status !== "temporarily_closed" &&
                      location.opening_status !== "permanently_closed"
                    );
                  })
                  .map((location: any) => {
                    if (
                      location.address &&
                      location.address.lat &&
                      location.address.long
                    ) {
                      return (
                        <Marker
                          key={"marker_" + location.id}
                          position={{
                            lat: Number(location.address.lat),
                            lng: Number(location.address.long),
                          }}
                          onClick={() => setSelected(location)}
                          onMouseOver={() => setHovered(location)}
                          onMouseOut={() => setHovered(null)}
                          icon={{
                            path: "M15.3867 29.8013L15.48 29.8547L15.5173 29.876C15.6654 29.9561 15.831 29.998 15.9993 29.998C16.1676 29.998 16.3333 29.9561 16.4813 29.876L16.5187 29.856L16.6133 29.8013C17.1348 29.4921 17.6437 29.1621 18.1387 28.812C19.4201 27.9073 20.6174 26.889 21.716 25.7693C24.308 23.116 27 19.1293 27 14C27 11.0826 25.8411 8.28473 23.7782 6.22183C21.7153 4.15893 18.9174 3 16 3C13.0826 3 10.2847 4.15893 8.22183 6.22183C6.15893 8.28473 5 11.0826 5 14C5 19.128 7.69333 23.116 10.284 25.7693C11.3822 26.8889 12.579 27.9072 13.86 28.812C14.3554 29.1621 14.8647 29.4921 15.3867 29.8013ZM16 18C17.0609 18 18.0783 17.5786 18.8284 16.8284C19.5786 16.0783 20 15.0609 20 14C20 12.9391 19.5786 11.9217 18.8284 11.1716C18.0783 10.4214 17.0609 10 16 10C14.9391 10 13.9217 10.4214 13.1716 11.1716C12.4214 11.9217 12 12.9391 12 14C12 15.0609 12.4214 16.0783 13.1716 16.8284C13.9217 17.5786 14.9391 18 16 18Z",
                            fillColor:
                              selected?.id === location.id
                                ? "#95C93D"
                                : "rgb(19, 41, 75)",
                            fillOpacity: 1,
                            strokeWeight: 1,
                            strokeColor: "#fff",
                            scale:
                              selected?.id === location.id ||
                              hovered?.id === location.id
                                ? 1.5
                                : 0.9,
                            anchor: new google.maps.Point(15, 30),
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
            </Map>
          )}
        </div>

        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-md-5">
              {!address &&
              (!latitude || !longitude) &&
              !state &&
              filters.length === 0 &&
              locations.length === 0 ? (
                <EmptyResults message="" />
              ) : locationsLoading || geolocationLoading ? (
                <LoadingSkeleton />
              ) : error ? (
                <EmptyResults message="Error. Please try again." />
              ) : !Array.isArray(locations) ? (
                <EmptyResults message="Error. Please try again." />
              ) : locations.length === 0 ? (
                <EmptyResults message="No results found in your location. Please try searching in another area." />
              ) : (
                <LocationSearchResults
                  locations={locations.filter((location: any) => {
                    return (
                      location.opening_status !== "temporarily_closed" &&
                      location.opening_status !== "permanently_closed"
                    );
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <ModalFilters />
    </>
  );
}

const EmptyResults = (props: { message: string }) => {
  return (
    <div className="col-12" style={{ minHeight: "50vh" }}>
      <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mt-2">
        <p className="mb-0">
          <strong className="text-text-hard">{props.message}</strong>
        </p>
      </div>
      <hr className="my-3" />
    </div>
  );
};

const LoadingSkeleton: React.FC = () => {
  return (
    <div className="col-12">
      <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mt-2">
        <p className="mb-0">
          <strong className="text-text-hard">Looking for Locations</strong>
        </p>
      </div>
      <hr className="my-3" />
      <div className="row">
        {[...Array(8)].map((_, i) => (
          <Skeleton key={`m-${i}`} height={80} />
        ))}
      </div>
    </div>
  );
};
