import { useLocationsContext } from "contexts/locations-context";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const LocationSearchForm = ({
  geolocationLoading,
  setGeolocationLoading,
}: {
  geolocationLoading: boolean;
  setGeolocationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchParams] = useSearchParams();
  const [localAddress, setLocalAddress] = useState(
    searchParams.get("address") || ""
  );
  const { doSearch } = useLocationsContext();

  /**
   * Get the user's location from the browser geolocation API
   */
  const getLocation = () => {
    setGeolocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocalAddress(
            position.coords.latitude + ", " + position.coords.longitude
          );
          setGeolocationLoading(false);
          doSearch({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: undefined,
          });
        },
        () => {
          console.log("Geolocation not supported");
          setGeolocationLoading(false);
        }
      );
    } else {
      console.log("Geolocation not supported");
      setGeolocationLoading(false);
    }
  };
  const submit = (e: any) => {
    e.preventDefault();
    // Don't mix long/lat and address
    doSearch({
      address: localAddress,
      latitude: undefined,
      longitude: undefined,
    });
  };
  return (
    <>
      <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mb-2">
        <h4 className="mb-0">Find a Location</h4>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a className="fs-sm" href="#" onClick={() => getLocation()}>
          Use My Location
        </a>
      </div>
      <form>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            id="location-search"
            placeholder={
              geolocationLoading
                ? "Trying to find you..."
                : "Enter city, state or zip code..."
            }
            value={localAddress}
            disabled={geolocationLoading}
            onChange={(e) => setLocalAddress(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submit(e);
              }
            }}
            onFocus={() => {
              if (localAddress === "Current Location") {
                setLocalAddress("");
              }
            }}
          />

          <button
            className="btn btn-secondary btn-sm"
            type="button"
            id=""
            onClick={submit}
          >
            Search
          </button>
        </div>
        <p className="c-note mt-1">Enter city, state or zip code</p>
      </form>
    </>
  );
};
export default LocationSearchForm;
