import * as braze from "@braze/web-sdk";
import config from "config";
import * as Sentry from "@sentry/browser";

export enum BrazeEvent {
  Online_Order_Anon_Checkout = "Online_Order_Anon_Checkout",
  Online_Order_New_Account = "Online_Order_New_Account",
  Online_Order_Update_Account = "Online_Order_Update_Account",
  Rewards_Activity = "Rewards_Activity",
  Rewards_Enroll = "Rewards_Enroll",
}

export interface BrazeUser {
  email?: string;
  first_name?: string;
  last_name?: string;
  email_subscribe?: boolean;
  subscription_group_id?: string;
  subscription_state?: string;
  home_city?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  last_purch_dt?: string;
  last_store_num?: string;
  lead_source?: string;
}

export const purchaseEvent = (
  order_id: string,
  price: number,
  quantity: number,
  metadata?: any
) => {
  // Format the high level metadata
  braze.logPurchase(order_id, price, "USD", quantity, metadata);
};

export const brazeSendData = async (
  callback: ((success: boolean) => void) | undefined
) => {
  return new Promise(function (resolved, rejected) {
    braze.requestImmediateDataFlush((success) => {
      if (callback) {
        callback(success);
      }
      if (success) {
        resolved(success);
      } else {
        rejected(success);
      }
    });
  });
};

export const userModel = (user: any) => {};

export const userModelFromOrder = (order: any) => {
  let user: BrazeUser = {
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    phone: undefined,
  };

  try {
    let username = ["", ""];
    if (order && order.customer_name){
      username = order.customer_name.split(" ");
    }
    user = {
      email: order.u ?? undefined,
      first_name: username[0] ? username[0] : undefined,
      last_name: username[1] ? username[1] : undefined,
      phone: order.customer_phone ?? undefined,
    };
  } catch (e) {
    console.error("Error creating Braze user", e);
    Sentry.captureException(e);
  }
  return user;
};

/**
 * We can use this to log events once Braze and Nouria provide events and
 * data
 * @param event
 * @param data
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const brazeEvent = (event: BrazeEvent, data: any) => {
  //
  braze.logCustomEvent(event, data);
};
// initialize the SDK
const initBraze = () => {
  console.log("Braze API Key", config.BRAZE_API_KEY);
  if (!config.BRAZE_API_KEY) return;

  //  if (!config.ENVIRONMENT || config.ENVIRONMENT === "development") return;
  braze.initialize(config.BRAZE_API_KEY, {
    baseUrl: "sdk.iad-07.braze.com",
  });

  // optionally show all in-app messages without custom handling
  braze.automaticallyShowInAppMessages();

  // if you use Content Cards
  // braze.subscribeToContentCardsUpdates(function (cards: any) {
  //     // cards have been updated
  // });

  // optionally set the current user's external ID before starting a new session
  // you can also call `changeUser` later in the session after the user logs in
  // if (isLoggedIn){
  //     braze.changeUser(userIdentifier);
  // }

  // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
  braze.openSession();
  braze.toggleLogging();
};
export default initBraze;
