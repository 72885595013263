import { APIProvider } from "@vis.gl/react-google-maps";
import { rawAuthMenuIcon } from "components/icons/AuthMenu";
import config from "config";
import { AppContextProvider } from "contexts/app-context";
import { AuthContextProvider } from "contexts/auth-context";
import { CheckoutContextProvider } from "contexts/checkout-context";
import { RestaurantContextProvider } from "contexts/restaurant-context";
import initBraze from "integrations/braze";
import initSentry from "integrations/sentry";
import Checkout from "pages/Checkout";
import HistoryOrder from "pages/HistoryOrder";
import AuthPage from "pages/Login";
import Menu from "pages/Menu";
import MenuItem from "pages/MenuItem";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TAuthCookie } from "types";
import * as cookieUtils from "utils/storage";
import "./App.css";
import { COOKIE_NAME_AUTH, EVENT_NAME_ORDER_UPDATED } from "./constants";
import "./index.css";
import MegaMenu from "pages/MegaMenu";
import {
  getHtmlElementOrderAttributes,
  getHtmlElementRestaurantAttributes,
} from "utils/html";
import LocationFinder from "pages/Locations";

const nouriaStoreContainer = document.getElementById("nouria-store");
const nouriaCheckoutContainer = document.getElementById("nouria-checkout");
const nouriaLocatorContainer = document.getElementById("nouria-locator");
const nouriaLoginContainer = document.getElementById("nouria-login");
const nouriaMegaMenuContainer = document.getElementById("nouria-megamenu");

const escapeValue = (value: string | number) => {
  if (typeof value === "string") {
    // Check if it's a valid URI or contains only letters and numbers
    if (/^[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]+$/.test(value)) {
      return value;
    } else {
      // Escape special characters in strings that are not valid URIs or contain non-alphanumeric characters
      return value.replace(/[^a-zA-Z0-9]/g, "\\$&");
    }
  } else if (typeof value === "number") {
    // Convert numbers to string
    return value.toString();
  } else {
    return "";
  }
};

initSentry();
declare global {
  interface Window {
    nouria_react: any;
  }
}

const nouriaHistoryOrderContainer = document.getElementById(
  "nouria-history-order"
);
if (nouriaStoreContainer) {
  const restaurant = getHtmlElementRestaurantAttributes(nouriaStoreContainer);

  if (restaurant) {
    ReactDOM.createRoot(nouriaStoreContainer).render(
      <React.StrictMode>
        <AppContextProvider>
          <RestaurantContextProvider
            data={{
              restaurant,
            }}
          >
            <CheckoutContextProvider>
              <BrowserRouter basename={restaurant.url}>
                <Routes>
                  <Route path="/" element={<Menu />} />
                  <Route path="/:menuItemId" element={<MenuItem />} />
                </Routes>
              </BrowserRouter>
            </CheckoutContextProvider>
          </RestaurantContextProvider>
        </AppContextProvider>
      </React.StrictMode>
    );
  }
}

if (nouriaMegaMenuContainer) {
  const restaurant = getHtmlElementRestaurantAttributes(
    nouriaMegaMenuContainer
  );

  ReactDOM.createRoot(nouriaMegaMenuContainer).render(
    <React.StrictMode>
      <AppContextProvider options={{ showToastNotifications: false }}>
        <RestaurantContextProvider
          data={{
            restaurant,
          }}
          options={{
            createOrderOnEmpty: false,
          }}
        >
          <MegaMenu />
        </RestaurantContextProvider>
      </AppContextProvider>
    </React.StrictMode>
  );
}

if (nouriaCheckoutContainer) {
  const atts = getHtmlElementOrderAttributes(nouriaCheckoutContainer);

  if (atts) {
    ReactDOM.createRoot(nouriaCheckoutContainer).render(
      <React.StrictMode>
        <AppContextProvider>
          <RestaurantContextProvider data={{ order: { id: atts.id } }}>
            <CheckoutContextProvider>
              <Checkout />
            </CheckoutContextProvider>
          </RestaurantContextProvider>
        </AppContextProvider>
      </React.StrictMode>
    );
  }
}

// Bind the location search wrapped in an API provider for google maps
if (nouriaLocatorContainer) {
  ReactDOM.createRoot(nouriaLocatorContainer).render(
    <React.StrictMode>
      <AppContextProvider>
        <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/find-a-store" element={<LocationFinder />} />
              <Route path="/locations/:locationState" element={<LocationFinder />} />
            </Routes>
          </BrowserRouter>
        </APIProvider>
      </AppContextProvider>
    </React.StrictMode>
  );
}

// Bind the location search wrapped in an API provider for google maps
if (nouriaLoginContainer) {
  ReactDOM.createRoot(nouriaLoginContainer).render(
    <AppContextProvider>
      <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>
        <AuthContextProvider>
          <AuthPage />
        </AuthContextProvider>
      </APIProvider>
    </AppContextProvider>
  );
}

if (nouriaHistoryOrderContainer) {
  const orderId = nouriaHistoryOrderContainer.getAttribute("data-order-id");
  const token = nouriaHistoryOrderContainer.getAttribute("data-token");
  ReactDOM.createRoot(nouriaHistoryOrderContainer).render(
    <React.StrictMode>
      <AppContextProvider>
        <HistoryOrder orderId={orderId} token={token} />
      </AppContextProvider>
    </React.StrictMode>
  );
}

/**
 * INject a menu dynamically to the top based on the user's authentication state
 *
 * **WARNING** This bypasses normal react XSS protections
 * You must sanitize the username any data you add to this function.
 * Note that the username is sanitized by stripping out non email characters
 *
 * @returns boolean
 */
export const bindMenu = () => {
  const nouriaAuthMenuContainer = document.getElementById("nouria-login-menu");
  // This regex works because we don't actually care about preserving the email
  // This is just a visual clue that you are logged in

  if (!nouriaAuthMenuContainer) return;
  const authStrValue = window.localStorage.getItem(COOKIE_NAME_AUTH);
  if (authStrValue) {
    const auth = JSON.parse(authStrValue) as TAuthCookie;
    if (auth.expires_at > new Date().getTime()) {
      if (auth.username === undefined) return false;
      const username = auth.username?.replace(/[^a-zA-Z0-9@.]/g, "");
      nouriaAuthMenuContainer.innerHTML = `<a href="/auth">${rawAuthMenuIcon} ${username}</a>`;
      return true;
    }
  }
  nouriaAuthMenuContainer.innerHTML = `<a href="/auth" onclick="return window.nouria_react.redirectLogin()">${rawAuthMenuIcon} Login</a>`;
};

const redirectLogin = () => {
  const path = window.location.pathname;
  window.location.href = "/auth?auth_redirect=" + path;
  return false;
};

const bindHeaderElements = () => {
  // retrieve header container element
  const nouriaHeaderContainer = document.getElementById("nouria-header");
  const locationHeaderContainer = document.getElementById(
    "nouria-current-location"
  ) as HTMLAnchorElement | null;

  // ensure that header container element exists
  if (!nouriaHeaderContainer) {
    return;
  }

  if (!locationHeaderContainer) {
    return;
  }

  // grab active restaurant id (if currently browsing any)
  const nouriaStoreContainer = document.getElementById("nouria-store");
  const activeRestaurantId = nouriaStoreContainer
    ? nouriaStoreContainer.dataset.restaurantId
    : undefined;

  // grab the latest order from the cookie
  const latestOrder = activeRestaurantId
    ? cookieUtils.getRestaurantOrder(activeRestaurantId)
    : cookieUtils.getLatestOrder();

  // check if there is a latest order we can use
  if (!latestOrder) {
    // check if there we are currently browsing any restaurants
    if (activeRestaurantId) {
      // prevent placeholders from rendering and rather
      // wait for the order to get created
      return;
    }

    // check if the header placeholder elements already exist
    if (document.getElementsByClassName("header-placeholder").length > 0) {
      return;
    }

    // remove any and all header elements (will be replaced by placeholders)
    document.getElementsByClassName("header-cart")[0]?.remove();
    document.getElementsByClassName("header-cta")[0]?.remove();

    // create cart placeholder element
    const cartPlaceholderEl = document.createElement("a");
    cartPlaceholderEl.className = `header-placeholder header-cart`;
    cartPlaceholderEl.innerHTML = `&nbsp;`;

    // create order now button placeholder element
    const orderNowPlaceholderEl = document.createElement("a");
    orderNowPlaceholderEl.href = "/find-a-store?ordernow=true";
    orderNowPlaceholderEl.className = `header-placeholder header-cta btn btn-primary btn-sm`;
    orderNowPlaceholderEl.innerHTML = `Order Now <svg class="svg-icon ms-1"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/nouria/assets/sprite/icons.svg#icon-arrow-right"></use></svg>`;

    // append placeholder elements
    nouriaHeaderContainer.insertBefore(
      cartPlaceholderEl,
      document.getElementsByClassName("header-mobile-nav-toggle")[0]
    );
    nouriaHeaderContainer.insertBefore(
      orderNowPlaceholderEl,
      document.getElementsByClassName("header-mobile-nav-toggle")[0]
    );

    return;
  }

  // remove any and all placeholder elements
  const placeholderElements =
    document.getElementsByClassName("header-placeholder");
  if (placeholderElements.length > 0) {
    while (placeholderElements[0]) {
      placeholderElements[0].parentNode!.removeChild(placeholderElements[0]);
    }
  }

  // check if the header cart elements need to be created
  if (document.getElementsByClassName("header-cart").length === 0) {
    // create cart element
    const cartEl = document.createElement("a");
    cartEl.id = "header-cart-link";
    cartEl.href = `/checkout/${escapeValue(latestOrder.id)}`;
    cartEl.className = `header-cart`;
    cartEl.innerHTML = `<svg class="svg-icon "><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/nouria/assets/sprite/icons.svg#icon-cart"></use></svg> Cart <span id="nouria-cart-items">${escapeValue(
      latestOrder.items?.toString() ?? "0"
    )}</span>`;

    // create order now button element
    const orderNowEl = document.createElement("a");
    orderNowEl.href = escapeValue(latestOrder.restaurant.url ?? "");
    orderNowEl.id = "header-order-now";
    orderNowEl.className = `header-cta btn btn-primary btn-sm`;
    orderNowEl.innerHTML = `Order Now <svg class="svg-icon ms-1"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/nouria/assets/sprite/icons.svg#icon-arrow-right"></use></svg>`;
    orderNowEl.style.visibility = !!latestOrder.restaurant.url
      ? "visible"
      : "hidden";

    // Update location container
    locationHeaderContainer.href = escapeValue(
      latestOrder.restaurant.url ?? ""
    );
    locationHeaderContainer.innerHTML =
      `<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50016 6.04134V5.54134H6.50016V6.04134H7.50016ZM8.09012 2.90945C8.6925 3.51187 8.69225 4.48832 8.09029 5.08973L8.79708 5.79716C9.79018 4.80496 9.78993 3.19511 8.79725 2.20236L8.09012 2.90945ZM8.09029 5.08973C7.48767 5.6918 6.51122 5.69184 5.91009 5.09008L5.20261 5.79681C6.19498 6.79022 7.80464 6.7887 8.79708 5.79716L8.09029 5.08973ZM5.91009 5.09008C5.30804 4.4874 5.30801 3.51081 5.90974 2.90962L5.20296 2.20219C4.20964 3.19461 4.21116 4.80432 5.20261 5.79681L5.91009 5.09008ZM5.90974 2.90962C6.51227 2.30764 7.48807 2.30736 8.09012 2.90945L8.79725 2.20236C7.80424 1.20928 6.19549 1.21056 5.20296 2.20219L5.90974 2.90962ZM6.50016 6.04134V10.708H7.50016V6.04134H6.50016ZM12.0418 10.7081C12.0418 10.858 11.9718 11.0448 11.7536 11.262C11.5335 11.4811 11.1896 11.7015 10.7247 11.8972C9.79686 12.2879 8.48036 12.5415 7.00016 12.5415V13.5415C8.58055 13.5415 10.0349 13.2727 11.1127 12.8189C11.6507 12.5924 12.1184 12.3099 12.4591 11.9707C12.8017 11.6296 13.0418 11.2026 13.0418 10.7081H12.0418ZM7.00016 12.5415C5.51997 12.5415 4.20346 12.2879 3.27564 11.8972C2.81071 11.7015 2.46686 11.4811 2.24674 11.262C2.02853 11.0448 1.9585 10.858 1.9585 10.7081H0.958496C0.958496 11.2026 1.1986 11.6296 1.54124 11.9707C1.88196 12.3099 2.34967 12.5924 2.88758 12.8189C3.96545 13.2727 5.41978 13.5415 7.00016 13.5415V12.5415ZM7.84184 8.90262C9.11935 8.98794 10.2185 9.2626 10.9808 9.63515C11.7847 10.0281 12.0418 10.4347 12.0418 10.7081H13.0418C13.0418 9.81828 12.288 9.161 11.4199 8.73671C10.51 8.29205 9.2758 7.99616 7.90848 7.90484L7.84184 8.90262ZM1.9585 10.7081C1.9585 10.4347 2.21559 10.0281 3.01957 9.63515C3.78185 9.2626 4.88098 8.98794 6.15848 8.90262L6.09184 7.90484C4.72453 7.99616 3.49032 8.29205 2.58047 8.73671C1.71233 9.161 0.958496 9.81828 0.958496 10.7081H1.9585Z" fill="white"/></svg> ` +
        latestOrder.restaurant.name?.replace(/[^a-zA-Z0-9@. ]/g, "") ?? "";
    locationHeaderContainer.style.visibility = !!latestOrder.restaurant.url
      ? "visible"
      : "hidden";

    // append elements
    nouriaHeaderContainer.insertBefore(
      cartEl,
      document.getElementsByClassName("header-mobile-nav-toggle")[0]
    );
    nouriaHeaderContainer.insertBefore(
      orderNowEl,
      document.getElementsByClassName("header-mobile-nav-toggle")[0]
    );
  } else {
    // retrieve cart count element
    const cartItemsEl = document.getElementById("nouria-cart-items");

    // ensure that cart count element exists
    if (!cartItemsEl) {
      return;
    }

    // update the cart count element with fresh value
    cartItemsEl.innerHTML = `${escapeValue(
      latestOrder.items?.toString() ?? "0"
    )}`;
    const cartEl = document.getElementById(
      "header-cart-link"
    ) as HTMLAnchorElement | null;
    if (cartEl) {
      cartEl.href = `/checkout/${escapeValue(latestOrder.id)}`;
    }
    const orderNowEl = document.getElementById(
      "header-order-now"
    ) as HTMLAnchorElement | null;
    if (orderNowEl) {
      orderNowEl.href = escapeValue(latestOrder.restaurant.url ?? "");
      orderNowEl.style.visibility = !!latestOrder.restaurant.url
        ? "visible"
        : "hidden";
    }
    locationHeaderContainer.href = escapeValue(
      latestOrder.restaurant.url ?? ""
    );
    locationHeaderContainer.innerHTML =
      `<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.50016 6.04134V5.54134H6.50016V6.04134H7.50016ZM8.09012 2.90945C8.6925 3.51187 8.69225 4.48832 8.09029 5.08973L8.79708 5.79716C9.79018 4.80496 9.78993 3.19511 8.79725 2.20236L8.09012 2.90945ZM8.09029 5.08973C7.48767 5.6918 6.51122 5.69184 5.91009 5.09008L5.20261 5.79681C6.19498 6.79022 7.80464 6.7887 8.79708 5.79716L8.09029 5.08973ZM5.91009 5.09008C5.30804 4.4874 5.30801 3.51081 5.90974 2.90962L5.20296 2.20219C4.20964 3.19461 4.21116 4.80432 5.20261 5.79681L5.91009 5.09008ZM5.90974 2.90962C6.51227 2.30764 7.48807 2.30736 8.09012 2.90945L8.79725 2.20236C7.80424 1.20928 6.19549 1.21056 5.20296 2.20219L5.90974 2.90962ZM6.50016 6.04134V10.708H7.50016V6.04134H6.50016ZM12.0418 10.7081C12.0418 10.858 11.9718 11.0448 11.7536 11.262C11.5335 11.4811 11.1896 11.7015 10.7247 11.8972C9.79686 12.2879 8.48036 12.5415 7.00016 12.5415V13.5415C8.58055 13.5415 10.0349 13.2727 11.1127 12.8189C11.6507 12.5924 12.1184 12.3099 12.4591 11.9707C12.8017 11.6296 13.0418 11.2026 13.0418 10.7081H12.0418ZM7.00016 12.5415C5.51997 12.5415 4.20346 12.2879 3.27564 11.8972C2.81071 11.7015 2.46686 11.4811 2.24674 11.262C2.02853 11.0448 1.9585 10.858 1.9585 10.7081H0.958496C0.958496 11.2026 1.1986 11.6296 1.54124 11.9707C1.88196 12.3099 2.34967 12.5924 2.88758 12.8189C3.96545 13.2727 5.41978 13.5415 7.00016 13.5415V12.5415ZM7.84184 8.90262C9.11935 8.98794 10.2185 9.2626 10.9808 9.63515C11.7847 10.0281 12.0418 10.4347 12.0418 10.7081H13.0418C13.0418 9.81828 12.288 9.161 11.4199 8.73671C10.51 8.29205 9.2758 7.99616 7.90848 7.90484L7.84184 8.90262ZM1.9585 10.7081C1.9585 10.4347 2.21559 10.0281 3.01957 9.63515C3.78185 9.2626 4.88098 8.98794 6.15848 8.90262L6.09184 7.90484C4.72453 7.99616 3.49032 8.29205 2.58047 8.73671C1.71233 9.161 0.958496 9.81828 0.958496 10.7081H1.9585Z" fill="white"/></svg> ` +
        latestOrder.restaurant.name?.replace(/[^a-zA-Z0-9@. ]/g, "") ?? "";
    locationHeaderContainer.style.visibility = !!latestOrder.restaurant.url
      ? "visible"
      : "hidden";
  }
};

bindMenu();
bindHeaderElements();

window.addEventListener(EVENT_NAME_ORDER_UPDATED, () => {
  bindHeaderElements();
});

// Initialze braze tracking site wide
// TODO: Figure out how to pass data from the react context into the braze tracking
// Consider creating a braze singleton that can be invoked within the react context
initBraze();
window.nouria_react = { bindMenu, redirectLogin };
